import React, { useState } from 'react';
import images from '../constants/images';
import Button from './ButtonWhite';
import pdf from '../assets/Menu.pdf';
import { animateScroll as scroll, Link } from 'react-scroll';
import { GiHamburgerMenu } from 'react-icons/gi';
import { BsXCircle } from 'react-icons/bs';

const Navbar = () => {
  const Links = [
    { name: 'ABOUT', link: 'About' },
    { name: 'MENU', link: 'Menu' },
    { name: 'CONTACT', link: 'Contact' },
  ];

  const [navbarOpen, setNavbarOpen] = useState(false);

  const handleToggle = () => {
    setNavbarOpen((prev) => !prev);
  };

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <div className="shadow-sm sticky w-full top-0 left-0">
      <div className="md:flex md:px-10 justify-between items-center bg-chajiGrey px-7 py-0 pt-4">
        <img
          src={images.chajiLogoWhite}
          className="object-contain h-18 w-24 cursor-pointer"
          alt="Chaji Creamery"
          onClick={toggleHome}
        />
        <button onClick={handleToggle}>
          {navbarOpen ? (
            <BsXCircle
              size="18"
              className="md:hidden absolute top-8 right-8 "
              color="white"
            />
          ) : (
            <GiHamburgerMenu
              size="22"
              className="md:hidden absolute top-8 right-8"
              color="white"
            />
          )}
        </button>
        <ul
          className={`md:flex md:items-center md:pb-0 absolute md:static bg-chajiGrey md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 pb-12 transition-all ease-in ${
            navbarOpen
              ? 'top-20 opacity-100'
              : 'top-[-490px] md:opacity-100 opacity-0'
          }`}
        >
          <li key="HOME" className="md:ml-8 text-xl my-7">
            <a
              onClick={toggleHome}
              className="text-white hover:text-slate-200 hover:underline transition-all scroll-smooth cursor-pointer"
            >
              Home
            </a>
          </li>
          <li key="About" className="md:ml-8 text-xl my-7">
            <Link
              to="About"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              className="text-white hover:text-slate-200 hover:underline duration-500 scroll-smooth cursor-pointer"
            >
              About
            </Link>
          </li>
          <li key="LocationHours" className="md:ml-8 text-xl my-7">
            <Link
              to="LocationHours"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              className="text-white hover:text-slate-200 hover:underline duration-500 scroll-smooth cursor-pointer"
            >
              Location & Hours
            </Link>
          </li>
          <li key="Menu" className="md:ml-8 text-xl my-7">
            <a
              href={pdf}
              target="_blank"
              className="text-white hover:text-slate-200 hover:underline duration-500 scroll-smooth cursor-pointer"
            >
              Menu
            </a>
          </li>
          <li key="CONTACT" className="md:ml-8 text-xl my-7">
            <Link
              to="Contact"
              smooth={true}
              duration={500}
              spy={true}
              offset={-80}
              className="text-white hover:text-slate-200 hover:underline duration-500 scroll-smooth cursor-pointer"
            >
              Contact
            </Link>
          </li>
          <form action="https://chajicreamery.square.site/" target="_blank">
            <Button>Order Now</Button>
          </form>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;

/* <li key="Apply Now" className="md:ml-8 text-xl my-7">
            <a
              href="https://forms.gle/Bw2z2Tk8pZswe8vb9"
              target="_blank"
              className="text-white hover:text-slate-200 hover:underline duration-500 scroll-smooth cursor-pointer"
            >
              Apply Now
            </a>
          </li>
          */
