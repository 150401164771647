import React from 'react';
import images from '../constants/images';
import Button from './ButtonWhite';
import pdf from '../assets/Menu.pdf';

const Hero = () => {
  return (
    <div className="md:flex">
      <div className="flex flex-col justify-center items-start md:w-1/2 pb-10 md:px-6 bg-chajiGrey">
        <h1 className="text-white text-center md:text-left shrink-0 lg:text-7xl text-5xl px-6 py-6">
          REDEFINING ICE CREAM
          <img
            src={images.ChajiStoreNew}
            alt="Hero Mobile"
            className="md:hidden py-4"
          />
        </h1>
        <p className="text-white px-6">
          Our team at Chaji Creamery offers unique ice cream flavors made with
          premium organic ingredients. We recreated the classic brown sugar boba
          bubble milk tea and thai ice tea into soft serve ice cream. We only
          use premium ceremonial grade matcha that comes from Kagoshima & Uji
          Japan in our matcha ice cream and drinks to ensure you get that
          authentic matcha taste. Finally, our red bean is cooked in house daily
          and are blended into our ice cream. Explore innovative flavors without
          compromise at Chaji.
        </p>
        <div className="flex flex-row w-full md:justify-start justify-evenly py-6">
          <form action="https://chajicreamery.square.site/" target="_blank">
            <Button>Order Now</Button>
          </form>
          <form action={pdf} target="_blank">
            <Button>View Menu</Button>
          </form>
        </div>
      </div>
      <div className="flex invisible md:visible md:px-6 w-1/2 items-center bg-chajiGrey">
        <img
          className="hidden md:block"
          src={images.ChajiStoreNew}
          alt="Hero Banner"
        />
      </div>
    </div>
  );
};

export default Hero;
