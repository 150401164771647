import React from 'react';
import images from '../constants/images';

const Footer = () => {
  return (
    <div className="bg-chajiGrey text-white">
      <div className="container mx-auto my-auto top-0 left-0 py-10">
        <div className="flex flex-col md:flex-row md:justify-between items-center">
          <div className="p-4 md:p-0">
            <h1 className="text-xl">Follow us</h1>
            <div className="flex justify-center gap-4 py-2">
              <a href="https://www.facebook.com/Chaji-Creamery-104504058889759">
                <img src={images.Facebook} alt="Facebook" className="w-6" />
              </a>
              <a href="https://www.instagram.com/chajicreamery/">
                <img src={images.Instagram} alt="Instagram" className="w-6" />
              </a>
              <img src={images.TikTok} alt="Tiktok" className="w-6" />
            </div>
          </div>
          <img
            src={images.chajiLogoWhite}
            alt="Chaji Logo"
            className="object-contain h-18 w-24 py-2 md:py-0"
          />
          <div className="p-6 md:p-0 flex flex-col items-center">
            <h1 className="text-xl md:py-0">Chaji Creamery</h1>
            <h1 className="text-sm md:py-0">
              293 Newport Ave, Quincy, MA 02170
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
