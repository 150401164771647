import React from 'react';
import { Element } from 'react-scroll';
import images from '../constants/images';

const About = () => {
  return (
    <div className="bg-white">
      <Element name="About"></Element>
      <div className="md:container md:mx-auto md:my-auto md:top-0 md:left-0 py-4 md:py-0">
        <div className="flex justify-center items-center px-4">
          <img
            src={images.MilkTeaParfait}
            alt="Milk Tea Parfait"
            className="max-w-sm hidden md:block"
          />
          <div>
            <h1 className="md:text-5xl text-center md:text-left text-5xl md:py-5">
              Savor the Moment
            </h1>
            <p className="md:text-lg py-4">
              Our soft serve features ingredients such as Premium Ceremonial
              Grade Matcha Powder, Organic Cold Brewed Thai Tea Leaves soaked
              for over 48 hours, as well as freshly cooked red beans that are
              blended into our fresh, daily made organic ice cream bases. Our
              team at Chaji Creamery looks to bring innovative flavors made with
              care to provide customers with new flavors that tastes great.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
