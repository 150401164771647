import React, { useState } from 'react';
import images from '../constants/images';
import { useMemo } from 'react';
import { Element } from 'react-scroll';
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

function Map() {
  const center = useMemo(() => ({ lat: 42.2665, lng: -71.02091 }), []);
  const infoCenter = useMemo(() => ({ lat: 42.2665, lng: -71.02091 }), []);

  const [markerOpen, setMarkerOpen] = useState(false);

  const handleToggle = () => {
    setMarkerOpen((prev) => !prev);
  };

  return (
    <GoogleMap zoom={16} center={center} mapContainerClassName="h-80 w-full">
      <Marker
        position={center}
        title={<h1>Chaji Creamery</h1>}
        onClick={handleToggle}
      />
      {markerOpen && (
        <InfoWindow
          position={infoCenter}
          onCloseClick={() => {
            setMarkerOpen(prev);
          }}
        >
          <div>
            <h1>Chaji Creamery</h1>
            <p>293 Newport Ave, Quincy, MA 02170</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

const LocationHours = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
  });

  return (
    <div>
      <Element name="LocationHours"></Element>
      <div className="md:container md:mx-auto md:my-auto md:top-0 md:left-0 py-4">
        <div className="flex justify-center items-center">
          <h1 className="md:text-5xl text-5xl px-4 md:px-0 text-center py-4">
            Location & Hours
          </h1>
        </div>
        <div className="md:flex md:justify-center md:items-center py-4 md:py-8">
          <div className="md:w-1/2 flex justify-center py-4 md:py-0">
            <div className="flex flex-col justify-center text-center ">
              <h1 className="text-2xl">293 Newport Ave, Quincy, MA 02170</h1>
              <div className="flex flex-1 justify-center flex-col text-center py-4">
                <div className="flex flex-col">
                  <h1 className="text-lg underline">Monday</h1>
                  <p>1:00 PM - 9:30 PM</p>
                </div>
                <div className="flex flex-col px-4">
                  <h1 className="text-lg underline">Tuesday</h1>
                  <p>1:00 PM - 9:30 PM</p>
                </div>
                <div className="flex flex-col px-4">
                  <h1 className="text-lg underline">Wednesday</h1>
                  <p>1:00 PM - 9:30 PM</p>
                </div>
                <div className="flex flex-col px-4">
                  <h1 className="text-lg underline">Thursday</h1>
                  <p>1:00 PM - 9:30 PM</p>
                </div>
                <div className="flex flex-col px-4">
                  <h1 className="text-lg underline">Friday</h1>
                  <p>1:00 PM - 10:00 PM</p>
                </div>
                <div className="flex flex-col px-4">
                  <h1 className="text-lg underline">Saturday</h1>
                  <p>1:00 PM - 10:00 PM</p>
                </div>
                <div className="flex flex-col px-4">
                  <h1 className="text-lg underline">Sunday</h1>
                  <p>1:00 PM - 10:00 PM</p>
                </div>
              </div>
            </div>
          </div>
          <div className="md:justify-start md:w-1/2 px-4 py-2 md:px-0 md:py-0 md:text-xl">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d738.1399069371901!2d-71.02118148484386!3d42.266575933712424!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e37dc52338f7a9%3A0x3007e295aa62895e!2sChaji%20Creamery!5e0!3m2!1sen!2sus!4v1650237129479!5m2!1sen!2sus"
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="w-full h-96"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationHours;
