import React from 'react';
import { MdEmail, MdOutlineSmartphone } from 'react-icons/md';
import { Element } from 'react-scroll';
import { IoPhonePortraitOutline } from 'react-icons/io5';
import { BsPhoneFill } from 'react-icons/bs';

const Contact = () => {
  return (
    <div>
      <Element name="Contact"></Element>
      <div className="md:container md:mx-auto md:my-auto md:top-0 md:left-0 py-4">
        <div className="flex justify-center items-center">
          <h1 className="text-5xl">Contact Us</h1>
        </div>
        <div className="md:flex md:justify-center md:items-center py-4 md:py-8">
          <div className="md:justify-start md:w-1/2 px-4 py-2 md:px-0 md:py-0 md:text-xl">
            <p>
              For any questions / feedback or inquiries regarding our products
              and services, please feel free to contact our store phone or email
              us at ChajiCreamery@gmail.com or (617) 992-4653. If you would like
              to join our team, please apply to the form that is under the Apply
              Now tab on the navigation bar.
            </p>
          </div>
          <div className="md:w-1/2 flex justify-center py-4 md:py-0">
            <div className="flex flex-col">
              <div className="flex flex-row items-center">
                <MdEmail className="text-2xl fill-white bg-chajiGrey rounded-md border-solid w-8 md:w-14" />
                <h1 className="text-xl px-6">Chajicreamery@gmail.com</h1>
              </div>
              <div className="flex flex-row items-center py-4">
                <MdOutlineSmartphone className="text-2xl fill-white bg-chajiGrey rounded-md border-solid w-8 md:w-14" />
                <h1 className="text-xl px-6">(617) 992-4653</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
