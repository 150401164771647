import logo from '../assets/placeHolderLogo.png';
import chajiLogo from '../assets/chajiLogo.png';
import chajiLogoWhite from '../assets/chajiLogoWhite.png';
import chajiMenu from '../assets/chajiMenu.png';
import chajiBanner from '../assets/chajiBanner.png';
import Instagram from '../assets/instagram.svg';
import Facebook from '../assets/facebook.svg';
import TikTok from '../assets/tiktok.svg';
import chajiStore from '../assets/chajiStore.jpg';
import ChajiStoreNew from '../assets/ChajiStoreNew.jpg';
import YuzuMango from '../assets/YuzuMango.png';
import MilkTeaParfait from '../assets/MTspecial.png';

const exportedImages = {
  logo,
  chajiLogo,
  chajiLogoWhite,
  chajiMenu,
  chajiBanner,
  Instagram,
  Facebook,
  TikTok,
  chajiStore,
  YuzuMango,
  MilkTeaParfait,
  ChajiStoreNew,
};

export default exportedImages;
