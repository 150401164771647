import Navbar from './Components/Navbar';
import Hero from './Components/Hero';
import About from './Components/About';
import Footer from './Components/Footer';
import Contact from './Components/Contact';
import LocationHours from './Components/LocationHours';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Contact />
      <LocationHours />
      <Footer />
    </div>
  );
}

export default App;
